@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-ThinItalic.a809cd89.woff2) format("woff2"),
    url(/static/media/LibreFranklin-ThinItalic.116158e8.woff) format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-SemiBold.6e916e18.woff2) format("woff2"),
    url(/static/media/LibreFranklin-SemiBold.be863a3f.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-Light.fee1a255.woff2) format("woff2"),
    url(/static/media/LibreFranklin-Light.938d05ee.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-MediumItalic.aef480b4.woff2) format("woff2"),
    url(/static/media/LibreFranklin-MediumItalic.c4b3e9d0.woff) format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-LightItalic.c2332916.woff2) format("woff2"),
    url(/static/media/LibreFranklin-LightItalic.2f0f07b3.woff) format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-ExtraBold.aec37d72.woff2) format("woff2"),
    url(/static/media/LibreFranklin-ExtraBold.11bcb44f.woff) format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-Bold.aa9f2483.woff2) format("woff2"),
    url(/static/media/LibreFranklin-Bold.66fd4bce.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-Black.5aecab4f.woff2) format("woff2"),
    url(/static/media/LibreFranklin-Black.e520f121.woff) format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-BoldItalic.1918dd53.woff2) format("woff2"),
    url(/static/media/LibreFranklin-BoldItalic.b763daed.woff) format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-Thin.cc0dc1f4.woff2) format("woff2"),
    url(/static/media/LibreFranklin-Thin.bfa155d0.woff) format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-ExtraBoldItalic.3268d484.woff2)
      format("woff2"),
    url(/static/media/LibreFranklin-ExtraBoldItalic.a70e5b14.woff) format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-Italic.2fa3367a.woff2) format("woff2"),
    url(/static/media/LibreFranklin-Italic.39596994.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-BlackItalic.dfa0c27f.woff2) format("woff2"),
    url(/static/media/LibreFranklin-BlackItalic.5d783bb3.woff) format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-SemiBoldItalic.925f5844.woff2) format("woff2"),
    url(/static/media/LibreFranklin-SemiBoldItalic.dd22bba3.woff) format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-ExtraLightItalic.70343da6.woff2)
      format("woff2"),
    url(/static/media/LibreFranklin-ExtraLightItalic.293e200f.woff) format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-ExtraLight.a7d49ac4.woff2) format("woff2"),
    url(/static/media/LibreFranklin-ExtraLight.a728bdf9.woff) format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-Medium.198a5bb2.woff2) format("woff2"),
    url(/static/media/LibreFranklin-Medium.ad3b95f2.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url(/static/media/LibreFranklin-Regular.f54a67b7.woff2) format("woff2"),
    url(/static/media/LibreFranklin-Regular.73714343.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noirden";
  src: url(/static/media/Noirden-Regular.6c896a04.woff2) format("woff2"),
    url(/static/media/Noirden-Regular.931e2ecb.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noirden";
  src: url(/static/media/Noirden-ThinOblique.05d75ee3.woff2) format("woff2"),
    url(/static/media/Noirden-ThinOblique.f0d3cf7e.woff) format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Noirden";
  src: url(/static/media/Noirden-ExtraLightOblique.1c80a013.woff2) format("woff2"),
    url(/static/media/Noirden-ExtraLightOblique.78e048af.woff) format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Noirden";
  src: url(/static/media/Noirden-SemiBoldOblique.7819a8bd.woff2) format("woff2"),
    url(/static/media/Noirden-SemiBoldOblique.c39a8813.woff) format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Noirden";
  src: url(/static/media/Noirden-ExtraLight.e24f9a38.woff2) format("woff2"),
    url(/static/media/Noirden-ExtraLight.b05ae48c.woff) format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Noirden";
  src: url(/static/media/Noirden-Light.f8a1607a.woff2) format("woff2"),
    url(/static/media/Noirden-Light.05695507.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Noirden";
  src: url(/static/media/Noirden-LightOblique.cfcf1021.woff2) format("woff2"),
    url(/static/media/Noirden-LightOblique.b31ffc64.woff) format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Noirden";
  src: url(/static/media/Noirden-SemiBold.d33203d4.woff2) format("woff2"),
    url(/static/media/Noirden-SemiBold.c8e42dbb.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noirden";
  src: url(/static/media/Noirden-Bold.2bc51b2c.woff2) format("woff2"),
    url(/static/media/Noirden-Bold.8a006dd1.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Noirden";
  src: url(/static/media/Noirden-Thin.40b18244.woff2) format("woff2"),
    url(/static/media/Noirden-Thin.bf93439c.woff) format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Noirden";
  src: url(/static/media/Noirden-RegularOblique.b929eb57.woff2) format("woff2"),
    url(/static/media/Noirden-RegularOblique.0ea8a93b.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Noirden";
  src: url(/static/media/Noirden-BoldOblique.93ccd3fd.woff2) format("woff2"),
    url(/static/media/Noirden-BoldOblique.776aa88e.woff) format("woff");
  font-weight: bold;
  font-style: italic;
}

