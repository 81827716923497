@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-ThinItalic.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-Light.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-MediumItalic.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-LightItalic.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-ExtraBold.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-Bold.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-Black.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-BoldItalic.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-Thin.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-ExtraBoldItalic.woff2")
      format("woff2"),
    url("../assets/fonts/LibreFranklin-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-Italic.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-BlackItalic.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-SemiBoldItalic.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-ExtraLightItalic.woff2")
      format("woff2"),
    url("../assets/fonts/LibreFranklin-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-ExtraLight.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-Medium.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Libre Franklin";
  src: url("../assets/fonts/LibreFranklin-Regular.woff2") format("woff2"),
    url("../assets/fonts/LibreFranklin-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noirden";
  src: url("../assets/fonts/Noirden-Regular.woff2") format("woff2"),
    url("../assets/fonts/Noirden-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Noirden";
  src: url("../assets/fonts/Noirden-ThinOblique.woff2") format("woff2"),
    url("../assets/fonts/Noirden-ThinOblique.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Noirden";
  src: url("../assets/fonts/Noirden-ExtraLightOblique.woff2") format("woff2"),
    url("../assets/fonts/Noirden-ExtraLightOblique.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Noirden";
  src: url("../assets/fonts/Noirden-SemiBoldOblique.woff2") format("woff2"),
    url("../assets/fonts/Noirden-SemiBoldOblique.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Noirden";
  src: url("../assets/fonts/Noirden-ExtraLight.woff2") format("woff2"),
    url("../assets/fonts/Noirden-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Noirden";
  src: url("../assets/fonts/Noirden-Light.woff2") format("woff2"),
    url("../assets/fonts/Noirden-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Noirden";
  src: url("../assets/fonts/Noirden-LightOblique.woff2") format("woff2"),
    url("../assets/fonts/Noirden-LightOblique.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Noirden";
  src: url("../assets/fonts/Noirden-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Noirden-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noirden";
  src: url("../assets/fonts/Noirden-Bold.woff2") format("woff2"),
    url("../assets/fonts/Noirden-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Noirden";
  src: url("../assets/fonts/Noirden-Thin.woff2") format("woff2"),
    url("../assets/fonts/Noirden-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Noirden";
  src: url("../assets/fonts/Noirden-RegularOblique.woff2") format("woff2"),
    url("../assets/fonts/Noirden-RegularOblique.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Noirden";
  src: url("../assets/fonts/Noirden-BoldOblique.woff2") format("woff2"),
    url("../assets/fonts/Noirden-BoldOblique.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}
